import ReactPlayer from 'react-player'

import '../../stylesheets/Actividades.css';
import '../../stylesheets/Arrial.css';

import arial1 from "../../imagenes/excursiones/arrial.jpg";

const Arrial = () => {
	return (

		<section className="seccionActividades container-lg">
					<h2> Paseo en ARRIAL DO CABO </h2>

			<article>
				<p>Saldrás de Buzios de  mañana, y te trasladaras por vía terrestre  hasta Arraial  (40km) , 
					donde embarcaras en una escuna con  música ambiente muy alegre y animación permanente.</p>
				<ReactPlayer 
					url= 'https://www.youtube.com/shorts/ZfbJltyMVQA'
					className='imgArrial1'
					controls
					playing= {true}
					muted={true}
					loop
					width='320px'
					height='560px'
					/>  	
	  		    <p>Ya embarcado, harás dos paradas de 40 minutos cada una, en las playas más hermosas del lugar, 
					conocido como el caribe brasilero justamente por lo trasparente del agua y lo blanco de la arena. </p>	
        <p>Finalizado el paseo se retorna a Arrail donde almorzarás durante 1 hora para luego volver a Buzios.</p> 
        <p>La duración total del paseo es de 9hs , en las que sin dudas pasarás una experiencia inolvidable y muy recomendable.</p> 
				<div className="final"></div>
			</article>
		</section>

		)
}

export default Arrial;



