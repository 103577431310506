import React, { useState } from "react";
import ReactFlagsSelect from "react-flags-select";


import {FaExchangeAlt} from 'react-icons/fa';
import {FcCurrencyExchange} from 'react-icons/fc';

import imagenFondo from '../imagenes/Venta-de-divisas.png';

import '../stylesheets/Divisas.css';

const estiloExchange = {
  cursor: 'pointer',
  padding: '2px',
  marginLeft: '2px',
  marginTop: '50px',
  backgroundColor: 'black',
  borderRadius: '10px',
  color: 'white',
  fontSize: '20px'
};
const estiloMonedas = {
  fontSize: '32px',
  marginTop: '0',
  marginLeft: '10px',
  position: 'absolute',
};

let country_list = {
  "AED" : "AE",
  "AFN" : "AF",
  "XCD" : "AG",
  "ALL" : "AL",
  "AMD" : "AM",
  "ANG" : "AN",
  "AOA" : "AO",
  "AQD" : "AQ",
  "ARS" : "AR",
  "AUD" : "AU",
  "AZN" : "AZ",
  "BAM" : "BA",
  "BBD" : "BB",
  "BDT" : "BD",
  "XOF" : "BE",
  "BGN" : "BG",
  "BHD" : "BH",
  "BIF" : "BI",
  "BMD" : "BM",
  "BND" : "BN",
  "BOB" : "BO",
  "BRL" : "BR",
  "BSD" : "BS",
  "NOK" : "BV",
  "BWP" : "BW",
  "BYR" : "BY",
  "BZD" : "BZ",
  "CAD" : "CA",
  "CDF" : "CD",
  "XAF" : "CF",
  "CHF" : "CH",
  "CLP" : "CL",
  "CNY" : "CN",
  "COP" : "CO",
  "CRC" : "CR",
  "CUP" : "CU",
  "CVE" : "CV",
  "CYP" : "CY",
  "CZK" : "CZ",
  "DJF" : "DJ",
  "DKK" : "DK",
  "DOP" : "DO",
  "DZD" : "DZ",
  "ECS" : "EC",
  "EEK" : "EE",
  "EGP" : "EG",
  "ETB" : "ET",
  "EUR" : "FR",
  "FJD" : "FJ",
  "FKP" : "FK",
  "GBP" : "GB",
  "GEL" : "GE",
  "GGP" : "GG",
  "GHS" : "GH",
  "GIP" : "GI",
  "GMD" : "GM",
  "GNF" : "GN",
  "GTQ" : "GT",
  "GYD" : "GY",
  "HKD" : "HK",
  "HNL" : "HN",
  "HRK" : "HR",
  "HTG" : "HT",
  "HUF" : "HU",
  "IDR" : "ID",
  "ILS" : "IL",
  "INR" : "IN",
  "IQD" : "IQ",
  "IRR" : "IR",
  "ISK" : "IS",
  "JMD" : "JM",
  "JOD" : "JO",
  "JPY" : "JP",
  "KES" : "KE",
  "KGS" : "KG",
  "KHR" : "KH",
  "KMF" : "KM",
  "KPW" : "KP",
  "KRW" : "KR",
  "KWD" : "KW",
  "KYD" : "KY",
  "KZT" : "KZ",
  "LAK" : "LA",
  "LBP" : "LB",
  "LKR" : "LK",
  "LRD" : "LR",
  "LSL" : "LS",
  "LTL" : "LT",
  "LVL" : "LV",
  "LYD" : "LY",
  "MAD" : "MA",
  "MDL" : "MD",
  "MGA" : "MG",
  "MKD" : "MK",
  "MMK" : "MM",
  "MNT" : "MN",
  "MOP" : "MO",
  "MRO" : "MR",
  "MTL" : "MT",
  "MUR" : "MU",
  "MVR" : "MV",
  "MWK" : "MW",
  "MXN" : "MX",
  "MYR" : "MY",
  "MZN" : "MZ",
  "NAD" : "NA",
  "XPF" : "NC",
  "NGN" : "NG",
  "NIO" : "NI",
  "NPR" : "NP",
  "NZD" : "NZ",
  "OMR" : "OM",
  "PAB" : "PA",
  "PEN" : "PE",
  "PGK" : "PG",
  "PHP" : "PH",
  "PKR" : "PK",
  "PLN" : "PL",
  "PYG" : "PY",
  "QAR" : "QA",
  "RON" : "RO",
  "RSD" : "RS",
  "RUB" : "RU",
  "RWF" : "RW",
  "SAR" : "SA",
  "SBD" : "SB",
  "SCR" : "SC",
  "SDG" : "SD",
  "SEK" : "SE",
  "SGD" : "SG",
  "SKK" : "SK",
  "SLL" : "SL",
  "SOS" : "SO",
  "SRD" : "SR",
  "STD" : "ST",
  "SVC" : "SV",
  "SYP" : "SY",
  "SZL" : "SZ",
  "THB" : "TH",
  "TJS" : "TJ",
  "TMT" : "TM",
  "TND" : "TN",
  "TOP" : "TO",
  "TRY" : "TR",
  "TTD" : "TT",
  "TWD" : "TW",
  "TZS" : "TZ",
  "UAH" : "UA",
  "UGX" : "UG",
  "USD" : "US",
  "UYU" : "UY",
  "UZS" : "UZ",
  "VEF" : "VE",
  "VND" : "VN",
  "VUV" : "VU",
  "YER" : "YE",
  "ZAR" : "ZA",
  "ZMK" : "ZM",
  "ZWD" : "ZW"
}

function Divisas() {
  const [selected, setSelected] = useState("");
  const [selected2, setSelected2] = useState("");

  var from ='';
  var to= '';

for(let trigrama in country_list){
  if(selected === country_list[trigrama]){
    from = trigrama;
    console.log(" FROM coincide con el siguiente trigrama : ", from);
  }
  if(selected2 === country_list[trigrama]){
    to = trigrama;
    console.log(" TO coincide con el siguiente trigrama : ", to);
  }  
}

const amount = document.querySelector(".monto input");

const mensajeArg = document.querySelector(".exchange-warning");
const mensajeArg2 = document.querySelector(".exchange-warning2");

function conversion(){
  let montoInicial = amount.value;
  const exchangeRateTxt = document.querySelector(".exchange-rate");
  if(montoInicial === "" || montoInicial === "0"){
    amount.value = "1";
    montoInicial = 1;
}
exchangeRateTxt.innerHTML = "Calculando conversión...";
let url = `https://v6.exchangerate-api.com/v6/78c7b978d17754be8aa74c2a/latest/${from}`;
fetch(url).then(response => response.json()).then(result =>{
  let exchangeRate = result.conversion_rates[to];


  if(from === "ARS" || to === "ARS"){
    exchangeRate += exchangeRate*0.037;

    console.log("el valor de conversion que queda es : ", exchangeRate);


    let totalExRate = montoInicial * exchangeRate;
    let cargaImpuestos = (totalExRate + totalExRate * 0.30 
       + totalExRate * 0.30).toFixed(2);
    mensajeArg.style.display = 'block';
    mensajeArg2.style.display = 'block';
    exchangeRateTxt.innerText = `${montoInicial} ${from} = ${cargaImpuestos} ${to}`;  
  }else{
    let totalExRate = (montoInicial * exchangeRate).toFixed(2);
    mensajeArg.style.display = 'none';
    mensajeArg2.style.display = 'none';
    exchangeRateTxt.innerText = `${montoInicial} ${from} = ${totalExRate} ${to}`;
  }

}).catch(() =>{
  exchangeRateTxt.innerText = "Something went wrong";
});
}

    return (
    <> 
    <img className="imagen" src={imagenFondo} alt="imagen-fondo-divisas" />
    <div className="contenidoDivisas">
      <header>Convertí tu moneda <FcCurrencyExchange style={estiloMonedas}/> </header>
      <div className="monto">
          <label htmlFor="montoInicial">Ingresá el monto a convertir</label>
          <input type="number" defaultValue="1" name="montoInicial" id="" />
      </div>
      <div className="zonaConversion">
        <div className="etiqueta">
          <p>Desde</p>
          <ReactFlagsSelect  
          selected={selected}
          onSelect={(code) => {setSelected(code)}}  
          countries={["US", "AR", "BO", "BR", "CL", "CO", "CU", "EC", "MX", "PY", "PE", "UY", "CA", "GB", "FR", "DE", "IT"]} 
          placeholder="moneda"  
          selectedSize={14}
          optionsSize={14}
          className="menu-flags"
          fullWidth={false}
          id="desdeFlag"
          />
        </div>

        <div className="icon" onClick={ () => {
          setSelected(selected2);
          setSelected2(selected);
          } }><FaExchangeAlt style={estiloExchange} /></div>
 
        <div className="etiqueta">
          <p>a</p>
          <ReactFlagsSelect  
          selected={selected2}
          onSelect={(code) => {setSelected2(code)}}   
          countries={["US", "AR", "BO", "BR", "CL", "CO", "CU", "EC", "MX", "PY", "PE", "UY", "CA", "GB", "FR", "DE", "IT"]} 
          placeholder="moneda"  
          selectedSize={14}
          optionsSize={14}
          className="menu-flags"
          fullWidth={false}
          id="haciaFlag"
          />   
        </div>


     </div>
     <div className="exchange-warning"><i>Para Argentina ésta cotización esta alcanzada por el Impuesto PAIS (30%) 
      y Percepción Bs. Personales (30%).
      quedando una conversión real de: </i> </div>
     <div className="exchange-rate"> </div>
     <div className="exchange-warning2"></div>     
     <button className="botonConvertir"  onClick={conversion}>Realizar la Conversión</button>

    </div>  
    </>     
    );
  }
  
  export default Divisas;