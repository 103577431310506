import Carousel from 'react-bootstrap/Carousel';

import '../../stylesheets/Opiniones.css';

import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';

import imgOpinion1 from "../../imagenes/opiniones/opinion11.jpeg";
import imgOpinion2 from "../../imagenes/opiniones/opinion22.jpeg";
import imgOpinion3 from "../../imagenes/opiniones/opinion33.jpeg";
import imgOpinion4 from "../../imagenes/opiniones/opinion44.jpeg";
import imgOpinion5 from "../../imagenes/opiniones/opinion55.jpeg";
import imgOpinion6 from "../../imagenes/opiniones/opinion66.jpeg";



function DarkVariantExample() {
  return (
    <section className="seccionActividades container">
      <h2 className="seccion-titulo">Opiniones</h2>
      <h3 className="seccion-descripcion">Algunos comentarios de viajeros</h3>        
    <Carousel variant="dark">
      <Carousel.Item>
            <div className="container  w-100">
              <img className="testimonio-imagen rounded-circle"  src={ imgOpinion1 } alt="foto 1" />
              <p className="testimonio-texto"> 👉 Excelente asesoramiento de Verónica para traslados, excursiones, posada.
              Siempre pendiente y predispuesta a toda consulta. Nos sentimos muy acompañadas. Gracias 😍😍. 
              Pasamos una semana increible 😎😎⛱️🏖️. </p>
              <div className="testimonio-info">
                <p className="cliente">Lu Avalos</p>
                <p className="cargo">2 de Ago. de 2022.</p>
              </div>
            </div>
      </Carousel.Item>
      <Carousel.Item>
      <div className="container  w-100">
              <img className="testimonio-imagen rounded-circle"  src={ imgOpinion2 } alt="foto 2" />
              <p className="testimonio-texto"> GRACIAS <strong>Buzios Maravilloso</strong>!!!!!!! 🌞🌞🌴🌴
              GRACIAS VERO 💯 EXCELENTES VACACIONES 🥳🚣‍♂️ 🏝️🏖️ La organización y asesoramiento de las 
              excursiones perfectas. ⛵ Hermosa la Posada Fantasy, excelente el servicio; los desayunos y 
              el personal 🍉 🍌☕  Impecable la puntualidad de los traslados.🚌 Muy recomendable</p>
              <div className="testimonio-info">
                <p className="cliente">Sergio Zweedijk</p>
                <p className="cargo">27 de Jul. de 2022.</p>
              </div>
            </div>
      </Carousel.Item>
      <Carousel.Item>
      <div className="container  w-100">
              <img className="testimonio-imagen rounded-circle"  src={ imgOpinion3 } alt="foto 3" />
              <p className="testimonio-texto"> Agradezco a Vero, de <strong>Buzios Maravilloso</strong>,
              por la atención que tuvo con nosotros, al recomendar estadía, traslados y excursiones a Buzios.
              Hicimos todo con ella, super confiable y atenta a nuestras dudas. Mil gracias Vero! Esperamos 
              volver en otro momento. Cariños !!!</p>
              <div className="testimonio-info">
                <p className="cliente">Maria Paula Pedrosa</p>
                <p className="cargo">5 de Jun. de 2022.</p>
              </div>
            </div>
      </Carousel.Item>
      <Carousel.Item>
      <div className="container  w-100">
              <img className="testimonio-imagen rounded-circle"  src={ imgOpinion4 } alt="foto 4" />
              <p className="testimonio-texto"> Gracias Verónica por tu excelente predisposición! El 
              transporte fue ideal y el alojamiento también, todo es puro disfrute ❤️🇧🇷</p>
              <div className="testimonio-info">
                <p className="cliente">Domi Pointis</p>
                <p className="cargo">17 de May. de 2022.</p>
              </div>
            </div>
      </Carousel.Item>
      <Carousel.Item>
      <div className="container  w-100">
              <img className="testimonio-imagen rounded-circle"  src={ imgOpinion5 } alt="foto 5" />
              <p className="testimonio-texto"> Muchas gracias Verónica de <strong>Buzios Maravilloso</strong>
               por acompañarnos desde el primer Río a Búzios, hospedaje en Búzios Casa, excrusión a Arrial do Cabo 
              y tudas las dudas que tuvimos... ahí estaban... asistiéndonos... excelente servicio y seriedad.</p>
              <div className="testimonio-info">
                <p className="cliente">Santiago Cepero</p>
                <p className="cargo">20 de Feb. de 2022.</p>
              </div>
            </div>
      </Carousel.Item>
      <Carousel.Item>
      <div className="container  w-100">
              <img className="testimonio-imagen rounded-circle"  src={ imgOpinion6 } alt="foto 6" />
              <p className="testimonio-texto">Que hermoso conocer un nuevo lugar con tu familia y que exista
              gente como  Verónica de <strong>Buzios Maravilloso</strong> que te guíe y te cuide como si 
              fuese parte de ella! Muchas gracias!!! </p>
              <div className="testimonio-info">
                <p className="cliente">Andrea Celeste Kedack</p>
                <p className="cargo">21 de Feb. de 2022.</p>
              </div>
            </div>
      </Carousel.Item>
    </Carousel>

    <a href="https://www.instagram.com/stories/highlights/17855738759304270/" target="_blank" rel="noopener noreferrer nofollow">
        <button type="button" class="btn btn-info botonOpiniones">
          Ver más opiniones
          <ArrowCircleRightIcon />
        </button>
      </a>
    </section>
  );
}

export default DarkVariantExample;