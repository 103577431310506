import React from 'react';

import { XAxis, YAxis, Tooltip, ResponsiveContainer, 
    ComposedChart, Area, ReferenceLine, 
    CartesianGrid,  LabelList } from "recharts";
import Spinner from "../meteorologia/components/Spinner";

import '../meteorologia/styles/Card.css';
// Función para calcular la diferencia de tiempo entre dos horas
// Función para calcular la diferencia de tiempo entre la hora actual y el objetivo
const calculateTimeDifference = (currentHour, targetHour) => {
    let diff = targetHour - currentHour;
    if (diff < 0) {
      // Si el tiempo es negativo, ajustar para el ciclo del día siguiente (añadir 24 horas)
      diff += 24;
    }
    const hours = Math.floor(diff);
    const minutes = Math.floor((diff - hours) * 60);
    return { hours, minutes };
  };
  
// Función para determinar si el nivel de agua está subiendo o bajando
const getMareaStatus = (tideData, currentHour) => {

   // console.log("contenido de currentHour: ", currentHour);
   // console.log("contenido de showData: ", showData);
    // Filtramos las mareas antes y después de la hora actual
    const previousTide = tideData.filter(tide => tide.time <= currentHour).pop();
    let nextTide = tideData.find(tide => tide.time > currentHour);

    // Si no hay próximos eventos (hemos llegado al final del día), tomar el primer evento del día siguiente
    if (!nextTide) {
        nextTide = tideData[0]; // Tomamos la primera marea del día actual para cerrar el ciclo
    }

    if (!previousTide) {
        return 'desconocido'; // No hay suficientes datos
    }

    // Si el siguiente nivel es más alto que el anterior, el agua está subiendo
    return nextTide.level > previousTide.level ? 'subiendo' : 'bajando';
};


  

// Convierte los datos de mareas en un formato adecuado para Recharts
const formatTideData = (tides) => {
    return tides.map((tide) => ({
        time: new Date(tide.time), // Extraer la hora de cada dato de marea
        level: tide.height.toFixed(2), // Redondear la altura
        type: tide.type, // Tipo de marea ("high" o "low")
      }));
};

// Función para formatear la hora en "HH:mm h"
const formatHour = (time) => {
    const date = new Date(time);
    return `${date.getHours()}:${date.getMinutes().toString().padStart(2, '0')} h`;
  };
  

const PlanillaMareas = ({ showData, loadingData, tides }) => {
 //   console.log("contenido de tides.length : ", tides.data.length);
    console.log("contenido de tides: ", tides);
    
    
    if (loadingData) {
        return <Spinner  />; // Muestra un spinner o mensaje de carga
    }

    if (!showData || !tides.data.length) {
        return <div>No hay datos de mareas disponibles.</div>;
    }

    // Formatear los datos de mareas solo para el día actual
    const tideData = formatTideData(tides.data);

    console.log("contenido de tideData:", tideData);

    // Obtener la primera pleamar y primera bajamar del día
    const firstPleamar = tideData.find(tide => tide.type === 'high');
    const firstBajamar = tideData.find(tide => tide.type === 'low');


    // Obtener la primera hora con datos y calcular la última hora (23)
    const firstHour = tideData[0].time.getHours(); // Primera hora con datos
    const lastHour = 23; // Mostramos hasta las 23:59

    // Obtener el tiempo actual en formato de 24 horas (0-23)
    const now = new Date();
    let currentHour = now; // Esto da la hora actual como un número entre 0 y 23
    // Determinar si el nivel de agua está subiendo o bajando
    const mareaStatus = getMareaStatus(tideData, currentHour);

    // Si no existe nextPleamar o nextBajamar, tomar la primera pleamar o bajamar del día
    let nextPleamar = tideData.find(tide => tide.type === "high" && tide.time > currentHour);
    let nextBajamar = tideData.find(tide => tide.type === "low" && tide.time > currentHour);

    // Cerrar ciclo: Si no hay pleamar o bajamar próximas, usar la primera pleamar y bajamar del día
    if (!nextPleamar) nextPleamar = firstPleamar;
    if (!nextBajamar) nextBajamar = firstBajamar;
/*
    console.log("contenido de nextPleamar:", nextPleamar);
    console.log("contenido de nextBajamar:", nextBajamar);
    console.log("contenido de firstPleamar:", firstPleamar);
    console.log("contenido de firstBajamar:", firstBajamar);
*/
    // Buscar la pleamar y bajamar anterior
    const lastPleamar = [...tideData].reverse().find(tide => tide.type === "high" && tide.time < currentHour);
    const lastBajamar = [...tideData].reverse().find(tide => tide.type === "low" && tide.time < currentHour);
    
    // Calcular el tiempo hasta la próxima marea (pleamar o bajamar)
    const timeToNextBajamar = nextBajamar ? calculateTimeDifference(currentHour, nextBajamar.time) : null;
    const timeToNextPleamar = nextPleamar ? calculateTimeDifference(currentHour, nextPleamar.time) : null;
 /*
    console.log("contenido de lastPleamar:", lastPleamar);
    console.log("contenido de lastBajamar:", lastBajamar);
*/
    const tideDataChart = [...tideData]; // Copia de los datos originales

// Si el primer evento es una pleamar, agregar lastBajamar al inicio con el mismo día, pero con hora 01:00
if (tideData[0].type === 'high' && lastBajamar) {
    const previousDayBajamar = { 
        ...lastBajamar,
        time: new Date(tideData[0].time), // Copiar la fecha del primer evento
        level: "0.3"
    };
    // Establecer la hora en 01:00
    previousDayBajamar.time.setHours(1, 0, 0, 0); // Hora 01:00 del mismo día
    tideDataChart.unshift(previousDayBajamar);
}
    
    // Si el primer evento es una bajamar, agregar lastPleamar al inicio con fecha del día anterior
    if (tideData[0].type === 'low' && lastPleamar) {
        const previousDayPleamar = { 
            ...lastPleamar, 
            time: new Date(tideData[0].time), // Copiar la fecha del primer evento
            level: "-0.3"
         };
        previousDayPleamar.time.setHours(1,0,0,0); 
        tideDataChart.unshift(previousDayPleamar);
       // console.log("Contenido de previousDayPleamar:", previousDayPleamar);
    }
    
    // Si el último evento es una bajamar, agregar firstPleamar al final con fecha del día siguiente
    if (tideData[tideData.length - 1].type === 'low' && firstPleamar) {
        const nextDayPleamar = { 
            ...firstPleamar, 
            time: new Date(tideData[tideData.length - 1].time), // Fecha del día siguiente
            level: "-0.3"
        };
        nextDayPleamar.time.setHours(23,0,0,0);
        tideDataChart.push(nextDayPleamar);
      //  console.log("Contenido de nextDayPleamar:", nextDayPleamar);
    }
    
    // Si el último evento es una pleamar, agregar firstBajamar al final con fecha del día siguiente
    if (tideData[tideData.length - 1].type === 'high' && firstBajamar) {
        const nextDayBajamar = { 
            ...firstBajamar, 
            time: new Date(tideData[tideData.length - 1].time), // Fecha del día siguiente
            level: "0.3"
        };
        nextDayBajamar.time.setHours(23,0,0,0);
        tideDataChart.push(nextDayBajamar);
      //  console.log("Contenido de nextDayBajamar:", nextDayBajamar);
    }
    
    // Mostrar los datos modificados para el gráfico
  //  console.log("Contenido de tideDataChart:", tideDataChart);
    
    return (
        <div className="planillaMareas">
            <h4>Predicciones de Mareas</h4>
            <ResponsiveContainer width="100%" height={400}>
                <ComposedChart
                    data={tideData}
                    margin={{ top: 20, right: 20, left: 20, bottom: 20 }}
                >
                    <CartesianGrid stroke="#f5f5f5" />
                    
                    {/* Eje X: Mostrar horas desde la primera hora con datos hasta 23:00 */}
                    <XAxis
                    dataKey="time"
                    type="number"
                    domain={[new Date(now).setHours(1, 0, 0, 0), new Date(now).setHours(23, 0, 0, 0)]} // Límites de 01:00 a 23:00
                    tickFormatter={(time) => formatHour(new Date(time))} // Formato HH:mm
                    tick={{ angle: -45, textAnchor: 'end', fontSize: 12 }} // Etiquetas en diagonal
                    scale="time"
                    />
                    
                    {/* Ocultamos las etiquetas y líneas del eje Y */}
                    <YAxis tick={false} axisLine={false} />
      
                    <Tooltip />
                    
                    {/* Definir los gradientes de color */}
                    <defs>
                        <linearGradient id="colorPositivo" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="0%" stopColor="#ff7f7f" stopOpacity={1}/>
                            <stop offset="100%" stopColor="#ff7f7f" stopOpacity={0.1}/>
                        </linearGradient>
                        <linearGradient id="colorNegativo" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="0%" stopColor="#f4a460" stopOpacity={0.1}/>
                            <stop offset="100%" stopColor="#f4a460" stopOpacity={0.1}/>
                        </linearGradient>
                    </defs>

                    {/* Área para valores negativos (color arena) */}
        {/* Área senoidal para las mareas */}
        <Area type="monotone" dataKey="level" stroke="#8884d8" fill="#8884d8">
          {/* Mostrar los valores de height sobre el gráfico */}
          <LabelList dataKey="level" position="top" formatter={(value) => `${value} m`} />
        </Area>
       
                    {/* Línea de referencia para mostrar la hora actual */}
                    <ReferenceLine x={now.getTime()} stroke="blue" label="Ahora" />
                    

                </ComposedChart>


            </ResponsiveContainer>

            {/* Texto importante sobre el estado actual */}
            <div style={{ backgroundColor: '#e0f7fa', padding: '10px', marginTop: '20px', borderRadius: '5px' }}>
                <p>
                    {nextPleamar || nextBajamar ? (
                        <>
                        
                        El nivel del agua está <strong style={{ color: mareaStatus === 'bajando' ? 'red' : 'green' }}>{mareaStatus}</strong> en estos momentos.
                        El próximo evento será la <strong>{nextPleamar && nextPleamar.time > currentHour ? 'pleamar' : 'bajamar'}</strong> a las <strong>
                            {nextPleamar && nextPleamar.time > currentHour ? formatHour(nextPleamar.time) : formatHour(nextBajamar.time)}.
                        </strong>
                                        
                        </>
                    ) : (
                        <>Estado actual: <strong style={{ color: mareaStatus === 'bajando' ? 'red' : 'blue' }}>{mareaStatus === 'bajando' ? 'bajamar' : 'pleamar'}</strong></>
                    )}
                </p>
            </div>

            {/* Información decorativa sobre mareas */}
            <div style={{ marginTop: '10px' }}>
                {nextPleamar || nextBajamar ? (
                <p>
Hoy, {now.toLocaleDateString('es-ES', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}, 
        en Armação dos Búzios, la próxima <strong style={{ color: 'blue' }}>pleamar</strong> será a las <strong>{formatHour(nextPleamar.time)}</strong> 
        y la próxima <strong style={{ color: 'red' }}>bajamar</strong> será a las <strong>{formatHour(nextBajamar.time)}</strong>.
         </p>
                ) : (
                    <p>
                        En el gráfico de pleamares y bajamares, podemos observar que la primera <strong style={{ color: 'blue' }}>pleamar</strong> fue a la <strong>{formatHour(tideData[0].time)}</strong> h 
                        y la siguiente <strong style={{ color: 'blue' }}>pleamar</strong> a las <strong>{formatHour(tideData[2].time)}</strong> h. 
                        La primera <strong style={{ color: 'red' }}>bajamar</strong> fue a las <strong>{formatHour(tideData[1].time)}</strong> h 
                        y la siguiente <strong style={{ color: 'red' }}>bajamar</strong> a las <strong>{formatHour(tideData[3].time)}</strong> h.
                    </p>
                )}
            </div>

        </div>
    );
};

export default PlanillaMareas;


