import React from 'react';
import ReactPlayer from 'react-player'
import { useState } from 'react';


import '../../stylesheets/FrameContenido.css';

import praia_barra from "../../imagenes/playas/vitorio.jpg"; //para cargar cualquier foto y no quede vacio


function FrameContenido(props){
	
	var tipo="PRAIA";

	if(props.muestra === '18' || props.muestra === 'publi') tipo ="";

	const mostrarVideo = document.querySelector(".contenido-video-playas");

	const mostrarFoto = document.querySelector(".contenido-foto-publi");



	var fotoExivir = praia_barra;

	console.log("contenido de mostrar video :", mostrarVideo);
	console.log("contenido de mostrar foto :", mostrarFoto);


	if( mostrarVideo != null) {  //EL PRIMER INGRESO EL CONTENIDO DE VIDEO Y FOTOS ES NULO
	
		if(props.muestra === 'publi'){ 
		fotoExivir = props.enlace;
		mostrarVideo.style.display = 'none';
		mostrarFoto.style.display = 'block';
			}else {
				mostrarVideo.style.display = 'block';
				mostrarFoto.style.display = 'none';
			} 
    }

	return(
    <div style={{display:'block'}}>
		   <div id='playa_seleccionada' className='tituloPlaya container'> {tipo} {props.nombre} </div>	
		   <div className='contenido-texto-video container'>
				<div className='contenido-texto-playas'>					
					{props.texto}					 
				</div>				

				<ReactPlayer 
					url= {props.enlace}
					className='contenido-video-playas'
					controls
					playing= {true}
					loop
					width='320px'
					height='560px'
					/>  
				
				<div id='muestraFoto' className="contenido-foto-publi" >
					<img src={fotoExivir} alt="foto_publi" />
				</div>
 
		   </div>	
    </div>
  );
}


export default FrameContenido;





