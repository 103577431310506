import React, { useState, useEffect } from 'react';

function ContadorVisitas() {
  const [contador, setContador] = useState(0);

  useEffect(() => {
    // Verificar si ya se ha almacenado el contador en localStorage
    if (localStorage.getItem('contador_visitas')) {
      // Si el contador ya está almacenado, incrementarlo en 1
      const contador = parseInt(localStorage.getItem('contador_visitas')) + 1;
      localStorage.setItem('contador_visitas', contador);
      setContador(contador);
    } else {
      // Si el contador no está almacenado, inicializarlo en 1982
      localStorage.setItem('contador_visitas', 1982);
      setContador(1);
    }
  }, []);

  return (
    <p style={{height:'auto', color:'white', fontSize:'0.8rem'}} >&#x1F441; - {contador} </p>
  );
}

export default ContadorVisitas;
