import Spinner from "./Spinner";
import '../styles/Card.css'

const estiloTest = {
    backgroundColor : "green",
}

const semana = ["Dom-","Lun-", "Mar-", "Mier-","Jue-","Vie-","Sab-","Dom-","Lun-", "Mar-", "Mier-"];

const Card = ({loadingData, showData, weather, forecast}) => {

    //fecha actual
    var today = new Date();
    var diaActual = today.getUTCDay();
    var week = semana[diaActual];
    var day = today.getDate();
    var month = today.getMonth()+1;
    var year = today.getFullYear();
    var date = week + day + '/' + month + '/' +year;


    var url = "";
    var iconUrl = "";

    const forecast12 = [];


    if(loadingData){
        return <Spinner  />;
    }
 // para abrir un icono tenemos que encabezar la url con "http://openweathermap.org/img/w/"
    if(showData){
        url = "http://openweathermap.org/img/w/";
        iconUrl = url + weather.weather[0].icon + ".png";

//   rutina para encontrar los proximos datos a mostrar
// dia siguiente a las 12hs  y a las 21 hs
     //    var diaActual = parseInt(forecast.list[0].dt_txt.substring(8, 10));

        //como la fecha esta totalmente como texto, se va concatenando para sacar la informacion
        //  dt_txt: "2023-02-02 18:00:00"
        //para obtener el formato correcto
                                            
   
      {
        forecast.list.map((contenido, index) => {
               parseInt(contenido.dt_txt.substring(11, 13)) === 12 ? (
                    forecast12.push(index)                
                ):( console.log("no entiendo que pasa ")
               )
                        })
   
            }
        }  
var indiceNuevo= 0;
const indiceAhora = (indx) => {
    indiceNuevo = indx +3;
    if(indiceNuevo > 39)indiceNuevo=39    
}

    return(
        <>
            {showData === true ? (
                    <div className="" style={estiloTest}>
                        <div className="card mb-2  mx-auto bg-dark text-light" >
                                <div className="col g-0">
                                    <div className="col">
                                        <h3 className="card-title">{weather.name}</h3>
                                        <p className="card-date">{date}</p>
                                        <h1 className="card-temp">{(weather.main.temp - 273.15).toFixed(1)}°C</h1>
                                        <p className="card-desc"><img src={iconUrl} alt="icon" />{weather.weather[0].description}</p>
                                        <img src="https://images.pexels.com/photos/1835718/pexels-photo-1835718.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" className="img-fluid rounded-start" alt=".." />
                                    </div> 
                                    <div className="card-body text-start mt-1">
                                        <h6 className="card-text">Temperatura máxima: {(weather.main.temp_max - 273.15).toFixed(1)}°C </h6>
                                        <h6 className="card-text">Temperatura mínima: {(weather.main.temp_min - 273.15).toFixed(1)}°C </h6>
                                        <h6 className="card-text">Sensación térmica: {(weather.main.feels_like - 273.15).toFixed(1)}°C </h6>
                                        <h6 className="card-text">Humedad: {weather.main.humidity}% </h6>
                                        <h6 className="card-text">Velocidad del viento: {weather.wind.speed} m/s</h6>
                                    </div>
                                    <hr />    
                                    <div className="text-center mb-2"><h3>Pronóstico extendido</h3></div>
                                    <hr />    

                                {   forecast12.map((indice, index) => (
                                    < div key={index}  >
                                   
                                {indiceAhora(indice)}
                                    <div className="row" >
                                        <div className="container text-center">{ semana[diaActual + index +1] + " " +
                                                        forecast.list[indice].dt_txt.substring(8, 10) + "/" + 
                                                        forecast.list[indice].dt_txt.substring(5, 7) + "/" +   
                                                        forecast.list[indice].dt_txt.substring(0, 4)}</div>
                                        <div className="col">
                                            <p className="text-center"> {forecast.list[indice].dt_txt.substring(11, 13)}hs</p>
                                            <p className="description text-center"><img src={url + forecast.list[indice].weather[0].icon + ".png"} alt="icon" /><br/>{forecast.list[indice].weather[0].description}</p>
                                            <p className="temp text-center">{(forecast.list[indice].main.temp - 273.15).toFixed(1)}°C </p>
                                        </div>
                                        <div className="col">
                                            <p className="text-center">{forecast.list[indiceNuevo].dt_txt.substring(11, 13)}hs</p>
                                            <p className="description text-center"><img src={url + forecast.list[indice].weather[0].icon + ".png"} alt="icon" /><br/>{forecast.list[indice].weather[0].description}</p>
                                            <p className="temp text-center">{(forecast.list[indice].main.temp - 273.15).toFixed(1)}°C </p>
                                        </div>
                                </div>
                                <hr />
                                </div>
                                ))      
                                }
                                </div>




                        </div>
                    </div>

                ):(
                    <h2 className="text-light">Sin datos</h2>
                )
            }
        </>    
    );
}

export default Card;