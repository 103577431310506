import { useState } from 'react';
import '../../stylesheets/Playas.css';

import praia_barra from "../../imagenes/playas/barra.jpg";
import praia_vitorio from "../../imagenes/playas/vitorio.jpg";


import FrameContenido from './FrameContenido';


import fotoPlayas from "../../imagenes/playas/playas.jpg";

//........ fotos publicidades .........................................

import imgPubli1 from "../../imagenes/publicidad/nonos_logo.png";
import imgPubli2 from "../../imagenes/publicidad/nonos_foto.png";
import imgPubli3 from "../../imagenes/publicidad/barracas_logo.png";
import imgPubli4 from "../../imagenes/publicidad/barracas_foto.png";
import imgPubli5 from "../../imagenes/publicidad/fantasy_logo.png";
import imgPubli6 from "../../imagenes/publicidad/fantasy_foto.jpeg";
import imgPubli7 from "../../imagenes/publicidad/clases_logo.png";
import imgPubli8 from "../../imagenes/publicidad/clases_foto.png";
 //  ...........................................................................

import {TfiInstagram} from 'react-icons/tfi';
import {GrLocation} from 'react-icons/gr';
import {FaWhatsappSquare} from 'react-icons/fa';



	//.............................................................................................................................
    const foca = () => {
		return(
		<div>
		<p> ( Extención de 50 mts.)</p>	
		<p>Situada entre las Playas do Forno y Ferradura, es una estrecha franja de arena rodeada de rocas, que forma una pequeña Playa semejante a una piscina natural, con aguas transparentes y tranquilas ideales para el buceo,y con una belleza increíble.</p>
	</div>
		)
	}

	const doCanto = () => {
		return(
		<div>
		<p> ( Extención de 1100 mts.)</p>	
	<p>Playa do Canto posee una estrecha franja de arena con  aguas generalmente cálidas y sin olas. A través de esta playa se llega a otras dos más pequeñas: Amores y Virgens. Do Canto posee casas y mansiones a lo largo de su extensión y es ideal para realizar caminatas observando las mismas y también el mar.</p>
	<p>Se localiza en el centro de Búzios junto a la Rúa das Pedras. Desprovista de bares, puestos de venta y vendedores ambulantes, es la playa ideal para aquellos que desean un baño verdaderamente tranquilo. Si bien está poblada de barcos, posee aguas muy cristalinas. </p>
		</div>
		)
	}
	
	const ferradura = () => {
		return(
		<div>
		<p> ( Extención de 1500 mts.)</p>	
	<p>Su agua es  calma, azul y clara, desprovista de olas y  su costa cubierta de arena fina completan el paisaje perfecto para familias con niños. Ferradura recibe su nombre por la forma de herradura que la caracteriza y que la transforman en una gran bahía protegida de los vientos y de las corrientes del mar, dando como resultado una especie de piscina natural.</p>	
	<p>Ferradura es el barrio más elegante de Búzios, está  repleto de lujosas mansiones y por lo tanto, es de esperar que su playa sea la más top. Es concurrida por políticos y famosos personajes de la farándula brasilera.</p>	
	<p>Además de la tranquilidad, la playa ofrece una completa oferta gastronómica sobre su canto izquierdo. Existen variados platos para degustar que incluyen pescados frescos y mariscos.</p>	
	<p>Asimismo existen diversas actividades para realizar, entre ellas paseos en banana-boat, kayak y lanchas. Además hay escuelitas de navegación a vela.</p>	
    <p>El canto derecho de la playa Ferradura suele ser más tranquilo, es un poco más agreste y desolado.</p>	
	</div>
		)
	}
	
	const joaoFernandez2 = () => {
		return(
		<div>
		<p> ( Extención de 600 mts.)</p>	
	<p>João Fernandes es una de las playas más populares de Búzios es muy concurrida y apreciada por los visitantes..Esta . ubicada  en el extremo noreste de la península , frente a la Isla Blanca.</p>	
	<p>João Fernandes conforma un semicírculo de aguas tranquilas y tibias, ideales para realizar snorkel, buceo y kayak en su atractivo y novedoso parque de corales submarino. También encontrarás la posibilidad de realizar paseos en botes y en "banana boat". La playa está dividida en dos partes por una formación rocosa muy fácil de atravesar. </p>
	<p>Sobre la playa misma existen diversos bares y barracas que ofrecen sabrosas comidas y refrescantes bebidas. Los vendedores ambulantes son una de las postales de esta playa, y ofrecen hermosas artesanías como así también comidas y productos autóctonos.</p>
		</div>
		)
	}
	
	const geriva = () => {
		return(
		<div>
		<p> ( Extención de 1600 mts.)</p>	
		<p>	Es una extensa ensenada con un ancho considerable. Se enfrenta a un mar abierto que ofrece muy buenas olas y buen viento, por lo tanto se convierte en la preferida de los cariocas, ya que es el lugar ideal para la práctica de diversos deportes náuticos como el surf, el windsurf y el kite-surf. Además, un importante punto de encuentro de jóvenes atraídos no sólo por los deportes náuticos sino también por el voley y fútbol vóley. </p>
		<p>Durante los veranos es muy común presenciar eventos musicales y fiestas nocturnas. Dotada de diversos servicios gastronómicos, es el lugar ideal para degustar excelentes pescados frescos y mariscos. </p>	
		<p>Para aquellos que concurren con familia y niños es recomendable acercarse al extremo norte de la playa, ya que ofrece aguas mucho más tranquilas. En este mismo sector encontrará además un camino de fácil acceso hacia la hermosa playa de Ferradurinha.</p>	
		</div>
		)
	}	
	
	const textoOssos = () => {
		return(
		<div>
		<p> ( Extención de 200 mts.)</p>	
		<p>
		En sus aguas tranquilas y templadas, hay gran cantidad de pequeñas embarcaciones, que  dan al lugar a una belleza y colorido único. Es casi un unto de paso para los turistas, ya que desde ella, podés elegir entre rutas para las playas Azeda, João Fernandes, y Armação.
		</p>
		<p>Alrededor de Praia dos Ossos, la plaza y las calles ofrecen una buena variedad de tiendas dedicadas al arte y a las antigüedades, genial para cualquiera que busque un recuerdo fuera de lo común de Búzios. La región también es una buena opción para aquellos que quieren estar cerca del centro, pero no necesariamente dentro del ajetreo y el bullicio. Está a escasos metros de Orla Bardot y es más fácil salir en coche y moverse sin el tráfico del centro.
		</p>	
		<p>Es común ver grupos de personas haciendo deportes de mañana muy temprano, tales como remo, natación, gimnasia y yoga, aprovechando la tranquilidad del lugar.</p>
		</div>
		)
	}	

	const forno = () => {
		return(
		<div>
		<p> ( Extención de 100 mts.)</p>	
		<p>La pequeña ensenada rodeada de una espesa vegetación, tiene arena gruesa con tonos rojizos y agua muy clara, transparente y fría. La playa es pequeña y semi-salvaje. Ideal para el buceo ya que el fondo rocoso,  abriga grutas naturales, preferida por aquellos que buscan paz y tranquilidad.</p>
		</div>
		)
	}	
	
	const tucuns = () => {
		return(
		<div>
		<p> ( Extención de 2500 mts.)</p>	
		<p>Es una playa continuación de Geriva. Distante, rustica, agreste y muy tranquila. Presenta vegetación típica y dunas. Sus aguas son claras y muy azules. El nombre está inspirado en un tipo de palmera típica de la región, llamado tucum.</p>
		<p>Se trata de una playa de gran extensión que  mira al Este. Es  ideal para caminar y correr. Al estar a mar abierto, sus grandes olas y viento son ideales para la práctica de deportes náuticos. </p>	
		</div>
		)
	}	
	
	const azedina = () => {
		return(
		<div>
		<p> ( Extención de 50 mts.)</p>	
		<p>Llegando desde el canto derecho de la playa de Azeda y a través de un corto y simple camino de piedras, se puede llegar. Es una playa extremadamente pequeña y encantadora. Forma parte de un área de protección ambiental. De exuberante vegetación y bordes rocosos, ofrece aguas cristalinas y un paisaje único. </p>
	    <p>Debido a la existencia de formaciones rocosas, es posible observar una especie de pileta artificial ideal para reposar. Al ser una playa protegida no existen bares ni barracas sobre la misma de todas maneras es muy común encontrarse con sencillos quioscos, como barcos improvisados que sirven bebidas o y que también ofrecen alquiler de sillas y sombrillas.
Estos espacios de servicios gastronómicos rudimentarios conviven también con la venta ambulante, bien típica en toda las playas de la aldea.</p>		
		<p>Otra de las posibles maneras de llegar es a través del uso del taxi acuático, servicio que  brinda la posibilidad de acceder a las playas más escondidas de Búzios.</p>	
		<p>Su orientación hacia el punto cardinal oeste le da a Azedinha una vista privilegiada de la puesta del sol.</p>	
</div>
		)
	}	
	
	const azeda = () => {
		return(
		<div>
		<p> ( Extención de 100 mts.)</p>	
		<p>se ubica entre las playas Ossos y  João Fernandes. Tanto Azeda como Azedinha forman parte de un área de protección ambiental. Azeda es una pequeña bahía de costas rocosas rodeada de vegetación. Tiene orientación hacia el Oeste por lo que se observan maravillosos atardeceres. </p>
		<p>Se accede a través de un camino y una escalera llegando desde la playa Ossos. Ofrece aguas calmas, claras y transparentes. Sobre su canto derecho existe un camino que lleva a la playa de Azedinha. Al ser una playa protegida no existen bares sobre la misma. Se recomienda llegar temprano, sobre todo en verano y días feriados, ya que el movimiento de gente es bastante importante.</p>	
		<p>En la playa existe una antigua mansión de estilo colonial construida a principios de siglo. Se trata de un punto tradicional de Búzios.</p>	
	</div>
		)
	}

	const brava = () => {
		return(
		<div>
		<p> ( Extención de 500 mts.)</p>
		<p>Se caracteriza por presentar fuertes olas y es la preferida por los amantes del surf. </p>	
		<p>Está rodeada de acantilados rocosos y vegetación con una increíble vista.</p>
		<p>Se divide en dos partes por medio de una formación rocosa que puede atravesarse a pie. Su canto derecho es más aislado y desértico y en su extremo, un sendero nos lleva a la playa nudista Olho de Boi. </p>
		<p>En su canto izquierdo encontrarás pequeños puestos que ofrecen variados platos y servicios de playa. </p>	
</div>
		)
	}
const manguinos = () => {
		return(
		<div>
		<p> ( Extención de 1300 mts.)</p>
		<p>Es una gran playa con una estrecha franja de arena . Muy concurrida por las embarcaciones en su canto derecho, pero no en el canto izquierdo. Sobre esta playa se encuentra el famoso paseo PORTO DA BARRA, con  locales de comidas muy bien presentados, que junto a la puesta de sol y el muelle propio, forman una postal muy apreciada por los aficionados a la fotografía y los turistas en general.</p>
		<p>Se trata de una playa ideal para deportes terrestes y acuáticos. Es además mucho más tranquila que el  resto de aquellas playas cercanas al centro buziano.</p>	
		<p>Manguinhos es famosa entre los marineros por sus buenas condiciones de viento, la mayoría de las competencias  deportivas acuaticas se realizan en ella. Existen clubes de navegación vela y se observa una activa colonia pesquera. </p>	
</div>
		)
	}	

const tartaruga = () => {
		return(
		<div>
		<p> ( Extención de 1700 mts.)</p>
		<p>Es una ensenada de aguas cristalinas y cálidas, ideales para el desove de 3 especies diferentes de tortugas marinas, que le dieron el nombre a la playa. Se ubica a 600 metros de la calle principal Ribeiro Dantas y se puede llegar por una calle recién asfaltada.</p>
		<p>Aquí podrá disfrutar de paseo en kayak y "banana boat". También podrá encontrar servicios de alquiler de equipos para buceo y snorkel para disfrutar de sus arrecifes.</p>	
		<p>Sobre su canto derecho encontrará además una pequeña y hermosa playa de piedras multicolores. Sobre su canto izquierdo encontrará un camino que lleva hacia la playa Manguinhos.</p>
		<p>Es muy concurrida y con buena infraestructura. Existen barracas que ofrecen servicios gastronómicas y equipos para la playa. Esta ubicada en una zona protegida y sin edificaciones. </p>
</div>
		)
	}	
const joaofernandinho = () => {
		return(
		<div>
		<p> ( Extención de 100 mts.)</p>
		<p>Se trata de una playa bien pequeña ubicada al norte de la península. De aguas tranquilas y cristalinas. Es accesible cruzando una formación rocosa desde la playa João Fernandes hacia el norte o bien a través de un sendero con escalones desde una de las calles. </p>
		<p>Es una playa ideal para practicar buceo y snorkel. Existe una variada fauna y flora marina alrededor de sus formaciones rocosas. João Fernandinho atrae a un público que prefiere mayor tranquilidad y es muy solicitada por buceadores y bañistas.  Además, cuenta con frondosos árboles que ofrecen muy buena sombra. Por estar bien protegida de los vientos, se transforma en el sitio ideal para un buen descanso. </p>	
</div>
		)
	}
const ferradurinha = () => {
		return(
		<div>
		<p>Es una hermosa playa rodeada de morros y acantilados. Una maravillosa bahía de aguas tranquilas, claras y arenas finas y blancas. Sin lugar a dudas la joya de Búzios. </p>
		<p>Se accede a través de un camino asfaltado, desde el canto izquierdo de la playa de Geribá. En aproximadamente 5 minutos a pie .  La playa es pequeña y paradisíaca.</p>	
		<p>Existen puestos de ventas sencillos que ofrecen bebidas y aperitivos .</p>	
</div>
		)
	}	

const rasa = () => {
		return(
		<div>
		<p> ( Extención de 4000 mts.)</p>
		<p>Es una de las playas más extensas de Búzios. De aguas tranquilas y poco profundas se encuentra en el extremo izquierdo de la playa de Manguinhos.</p>
		<p>El fuerte viento constante atrae a los amantes de windsurf  y el kitesurf. Es una playa ideal para programar caminatas o correr. Sus atardeceres son mágicos y se recomienda no perdérselos. 
Alrededor de la playa se encuentran casas de veraneo, algunas posadas y barrios cerrados de increíble belleza y moderna construcción.</p>
	
</div>
		)
	}
const vitorio = () => {
		return(
		<div>
			<p>Está al final de Praia Rasa.  Hay un gran muro de hormigón de una casa que forma un corredor, donde comienza un sendero que lleva menos de 10 minutos en recorrer, es súper tranquilo y marcado. </p>
			<p>Hay dos formas de llegar a lo alto del mirador: bajando a la playa y luego subiendo por las rocas, o entrando por un sendero algo cerrado en el bosque, antes de llegar a la playa.</p>
			<p>La dificultad del sendero es reducida, pero cuesta un poco encontrar la entrada para subir y, al ser remota, puede ser peligrosa, es por ello que conviene hacerlo en compañía de otra persona.</p>
			<p>La playa es una delicia!!! Pequeña, rodeado de rocas, con agua clara y muchas tortugas.  Si quieres explorar aún más, puedes tomar un pequeño sendero, bordeando las rocas, hasta salir a otra playa más pequeña. ¡Realmente es un paraíso!. Si tu idea es pasar el día ahí, te sugiero llevar sombrilla, agua y algo de comida ya que  no hay lugares de venta  y la sombra es muy poca.</p>
			<img className="fotos_playas img-fluid" src={praia_vitorio} alt="praia-citorio" />

</div>
		)
	}

const barra = () => {
		return(
		<div>
			<p>Situado junto al Muelle de Manguinhos y un poco más alejado del bullicio del centro, es un complejo que reúne lo mejor de la gastronomía, la moda y la decoración.</p>
			<p>Porto da Barra es una de las mejores opciones para quien busca dónde comer, por que tiene los mejores restaurantes.</p>
			<img className="fotos_playas img-fluid" src={praia_barra} alt="praia-barra" />
			<p>Ya sea para un almuerzo relajado con amigos, o incluso una cena romántica, conocerlo es una gran opción, en especial es el lugar perfecto para ver la maravillosa puesta de sol.</p>
</div>
		)
	}
	
	const textoArmasao = () => {
		return(
		<div>
		<p> ( Extención de 400 mts.)</p>	
		<p>
		Es una franja de arena estrecha y hermosa que recorre la costa occidental de Búzios. La playa está junto a la costanera principal conocida como ORLA BARDOT.	</p>
		<p>
		En las primeras horas de la mañana podes  ver los barcos de colores brillantes que se dirigen hacia el mar. Observar la estatua de tres pescadores que ingresan agua. Esta estatua es una creación de la escultora local Christina Motta y es uno de los elementos más característicos de la ciudad.
		</p>	
		<p>
		Junto a la costa , se encuentra la Avenida José Bento Ribeiro Dantas, que es la gran calle arbolada que cuenta con algunas de las tiendas, galerías, bares y restaurantes más populares de Búzios. Avanzando por esta calle hacia el norte podes llegar a la bella Iglesia de Santa Ana, que es un edificio blanco de poca altura con elementos de color azul rodeada de un bellísimo paisaje.
		</p>
		<p>
		Si caminas hacia el sur por la Avenida, encontrarás una estatua de la estrella de cine Brigitte Bardot, que alguna vez visitó esta ciudad. Más allá de la estatua se encuentra el hermoso Muelle de Mangue y la famosa Rua das Pedras. Aunque la Rua das Pedras es una extensión de la Avenida José Bento Ribeiro Dantas, esta parte de la calle se conoce como el centro de la vida nocturna de la ciudad.
		</p>
		<p>
		Desde el muelle, parten lanchas y taxis marítimos que hacen recorridos por las playas de Búzios transportando pasajeros. 	
		</p>
		</div>
		)
	}	

//	... AREA PUBLICIDADES..... 	....................................................................................
		const publi_nonos = () => {
			return(
			<div>
			<p> <strong>PIZZAS Y PASTAS</strong></p>
			<p>Somos una familia de Argentinos, te brindamos la mejor atención para que te sientas feliz y regreses siempre……</p>
			<p><TfiInstagram />   <a href="https://www.instagram.com/los_nonos_buzios/" target="_blank" rel="noopener noreferrer nofollow"> @los_nonos_buzios</a> </p>
			<p><GrLocation /> Rua Manuel Toribio das Farias 262 </p>

		</div>
			)
		}	

		const publi_barracas = () => {
			return(
			<div>
			<p> <strong>COMIDAS Y BEBIDAS</strong></p>
			<p>Disfruta de la mejor atención, brindada por sus dueños en la arena y junto al mar.</p>
			<p><TfiInstagram />   <a href="https://www.instagram.com/barracadeborabuzios/" target="_blank" rel="noopener noreferrer nofollow"> @barracadeborabuzios</a> </p>
			<p><GrLocation /> Playa Geriba, canto izquierdo </p>

		</div>
			)
		}	
		const publi_fantasy = () => {
			return(
			<div>
			<p> <strong>GUEST HOUSE</strong></p>
			<p>Habitaciones con vista al mar Piscina, sauna, desayuno.</p>
			<p>Reservas:</p>
			<p><TfiInstagram />   <a href="https://www.instagram.com/buziosmaravilloso/" target="_blank" rel="noopener noreferrer nofollow"> @buziosmaravilloso</a> </p>
			<p><FaWhatsappSquare /> +54 9 2932 540996 </p>

		</div>
			)
		}	
		const publi_clases = () => {
			return(
			<div>
			<p> <strong>Ensino Personalizado</strong></p>
			<br />
			<p>Matemática - Física - Química </p>
			<p>Pre vestibular</p>
			<p>Aulas de refor&#231;o</p>
			<p>Matemática recreativa</p>
			<p><FaWhatsappSquare />  +54 9 2932 400224.</p>
			<p><GrLocation /> Zona OSSOS </p>

		</div>
			)
		}
//..................................................................................................................

const listado_playas = [
	{
	nombre: 'Joao Fernández',
	texto: joaoFernandez2(),
	link: 'https://www.youtube.com/shorts/YRRq5NxuBQI',
	mostrar: '0'
  },
  {
	nombre: 'Ferradurinha',
	texto: ferradurinha(),
	link: 'https://www.youtube.com/embed/NdZUCKznv-U',
	mostrar: '1'	
  },
  {
	nombre: 'Joao Fernandinho',
	texto: joaofernandinho(),
	link: 'https://www.youtube.com/shorts/E9GbkCIqIZU',
	mostrar: '2'
  },
  {
	nombre: 'Azeda',
	texto: azeda(),
	link: 'https://www.youtube.com/shorts/9VVxPVfePNo',
	mostrar: '3'
  },
  {
	nombre: 'Azedinha',
    texto: azedina(),
	link: 'https://www.youtube.com/shorts/uKvvbQg21yg',
	mostrar: '4'
  },
  {
	nombre: 'Ossos',
	texto: textoOssos(),
	link: 'https://www.youtube.com/shorts/657tPCzkG4g',
	mostrar: '5'
  },
  {
	nombre: 'Armasao',
	texto: textoArmasao(),
	link: 'https://www.youtube.com/shorts/zGXHuLX8Qks',
	mostrar: '6'
  },
  {
	nombre: 'Canto',
	texto: doCanto(),
	link: 'https://www.youtube.com/shorts/MPMqu_KM1N8',
	mostrar: '7'
  },
  {
	nombre: 'Brava',
	texto: brava(),
	link: 'https://www.youtube.com/shorts/p3w-uKrAOm4',
	mostrar: '8'
  },
  {
	nombre: 'Tartaruga',
	texto: tartaruga(),
	link: 'https://www.youtube.com/shorts/WO-bkP3fmpw',
	mostrar: '9'
  },
  {
	nombre: 'Rasa',
	texto: rasa(),
	link: 'https://www.youtube.com/shorts/mr3h2qbtY7w',
	mostrar: '10'
  },
  {
	nombre: 'Manginhos',
	texto: manguinos(),
	link: 'https://www.youtube.com/embed/fbk-l9YCDFw',
	mostrar: '11'
  },
  {
	nombre: 'Forno',
	texto:forno(),
	link: 'https://www.youtube.com/shorts/Vlm7V6UoROs',
	mostrar: '12'
  },
  {
	nombre: 'Foca',
	texto: foca(),
	link: 'https://www.youtube.com/shorts/bZYw90JU0ME',
	mostrar: '13'
  },
  {
	nombre: 'Ferradura',
	texto: ferradura(),
	link: 'https://www.youtube.com/shorts/cQfnp_glEqM',
	mostrar: '14'
  },
  {
	nombre: 'Geriba',
	texto: geriva(),
	link: 'https://www.youtube.com/shorts/jzayA37IOCg',
	mostrar: '15'
  },
  {
	nombre: 'Tucuns',
	texto: tucuns(),
	link: 'https://www.youtube.com/shorts/xptVMi58eVU',
	mostrar: '16'
  },
  {
	nombre: 'Ponta do Pai Vitorio',
    texto: vitorio(),
	link: 'https://www.youtube.com/shorts/PSSW21nmTos',
	mostrar: '17'
  },
  {
	nombre: 'Porto da Barra',
	texto: barra(),
	link: 'https://www.youtube.com/embed/7454n4mmMYU',
	mostrar: '18'

  },
  {
	nombre: 'LOS NONOS',
	texto: publi_nonos(),
	link: imgPubli2,
	mostrar: 'publi'
  },
  {
	nombre: 'BARRACA DA DÉBORA',
	texto: publi_barracas(),
	link: imgPubli4,
	mostrar: 'publi'
  },
  {
	nombre: 'FANTASY BÚZIOS',
	texto: publi_fantasy(),
	link: imgPubli6,
	mostrar: 'publi'
  },
  {
	nombre: 'INGENIERO  ELVIS CACERES',
	texto: publi_clases(),
	link: imgPubli8,
	mostrar: 'publi'
  }                 
  

  
  
]


const Playas = () => {

	const [actual, setActual] = useState(listado_playas[0]);

	console.log("contenido actual : ", actual);
	return (
		<div className='contenidoPlayas'>
		   <div className='container text-center d-flex justify-content-center'>
			 <h1 >PLAYAS</h1> 
			 <h3 className='areaPlayaTitulo'>Selecioná las playas que te interesen y mirá su info mas abajo.</h3>
			</div> 
		    
		   <div className="contenedorPlayas">
			   <img className="imgPlayas" src={fotoPlayas} alt="fotos Mapa" />	
			   <div className='areaPlaya joaoFernandez' onClick={() => setActual(listado_playas[0])}> Joao Fernández </div>
			   <div className='areaPlaya joaoFernandino' onClick={() => setActual(listado_playas[2])}> Joao Fernandinho</div>
			   <div className='areaPlaya tartaruga' onClick={() => setActual(listado_playas[9])}> Tartaruga </div>
			   <div className='areaPlaya azeda' onClick={() => setActual(listado_playas[3])}> Azeda </div>
			   <div className='areaPlaya azedina' onClick={() => setActual(listado_playas[4])}>Azedinha</div>
			   <div className='areaPlaya ossos' onClick={() => setActual(listado_playas[5])}> Ossos </div>
			   <div className='areaPlaya armasao' onClick={() => setActual(listado_playas[6])}> Armasao </div>
			   <div className='areaPlaya canto' onClick={() => setActual(listado_playas[7])}> Canto </div>
			   <div className='areaPlaya brava' onClick={() => setActual(listado_playas[8])}> Brava </div>
			   <div className='areaPlaya mangunos' onClick={() => setActual(listado_playas[11])}> Manginhos </div>
			   <div className='areaPlaya forno' onClick={() => setActual(listado_playas[12])}> Forno </div>
			   <div className='areaPlaya foca' onClick={() => setActual(listado_playas[13])}> Foca </div>
			   <div className='areaPlaya ferradura' onClick={() => setActual(listado_playas[14])}> Ferradura </div>
			   <div className='areaPlaya geriba' onClick={() => setActual(listado_playas[15])}> Geriba </div>
			   <div className='areaPlaya ferradina' onClick={() => setActual(listado_playas[1])}> Ferradurinha </div>
			   <div className='areaPlaya tucuns' onClick={() => setActual(listado_playas[16])}> Tucuns </div>
			   <div className='areaPlaya rasa' onClick={() => setActual(listado_playas[10])}> Rasa </div>
			   <div className='areaPlaya pontaVitorio' onClick={() => setActual(listado_playas[17])}> Ponta do Pai Vitorio</div>
			   <div className='areaPlaya portoDaBarra' onClick={() => setActual(listado_playas[18])}> Porto da Barra</div>	


				<div onClick={() => setActual(listado_playas[19])} > 
				</div>
				<div onClick={() => setActual(listado_playas[20])} > 
					<img className="iconoPublicidad posicionBarracas"  src={ imgPubli3 } alt="foto publi2" />
				</div>		
				<div onClick={() => setActual(listado_playas[21])} > 
					<img className="iconoPublicidad posicionFantasy"  src={ imgPubli5 } alt="foto publi3" />
				</div>		
				<div onClick={() => setActual(listado_playas[22])} > 
					<img className="iconoPublicidad posicionClases"  src={ imgPubli7 } alt="foto publi4" />
				</div>									   

		   </div>

		   <FrameContenido nombre={actual.nombre} texto={actual.texto} 
				enlace={actual.link}  muestra={actual.mostrar}  />  




		</div>
		)
}

export default Playas;

