import '../stylesheets/Logo.css';

const Logo = ()=> {

	return(                 
		<div className="logo">
                    <p >BUZIOS</p>    
                    <p >MARAVILLOSO</p>         
        </div>
    );            

};

export  default Logo;